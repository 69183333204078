
import React from "react";

import './App.css';

var current_year = new Date().getFullYear();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://wswolves.b-cdn.net/Winston-Wolves-Logo-PRIMARY-2019-6inx6in-FC-266x300-1.png" />
        <div class="page_text page_width">
          The Wolves Shop is currently being revamped with updated gear!<br/><br/>
          For the latest Winston-Salem Wolves updates, visit the official Wolves site:<br/>
        </div>
        <a
          className="App-link"
          href="https://www.wswolves.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          WSWolves.com
        </a>

        <div class="page_text top_padding_copyright">
          &copy; {current_year} Winston-Salem Wolves
        </div>     
      </header>
    </div>
  );
}

export default App;
